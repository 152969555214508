@font-face {
    font-family: "League Spartan";
    src: local("LeagueSpartanVariableFontwght"), url("fonts/LeagueSpartanVariableFontwght.ttf") format("truetype");
}
.App {
    font-family: 'League Spartan';
    text-align: center;
    box-sizing: border-box;
    scroll-behavior: smooth;
}
* {
  --brand-hue: 183;
  --brand-saturation: 100%;
  --brand-lightness: 35%;
}

* {    
  --brand-light: hsl(var(--brand-hue), var(--brand-saturation), var(--brand-lightness));
  --text1-light: hsl(var(--brand-hue) var(--brand-saturation) 10%);
  --text2-light: hsl(var(--brand-hue) 30% 30%);
  --surface1-light: hsl(var(--brand-hue) 25% 90%);
  --surface2-light: hsl(var(--brand-hue) 20% 99%);
  --surface3-light: hsl(var(--brand-hue) 20% 92%);
  --surface4-light: hsl(var(--brand-hue) 20% 85%);
  --surface-shadow-light: hsl(var(--brand-hue) 10% calc(var(--brand-lightness)/1));
  --surface-shadow-strength: .2;
}

*{
  --brand-dark: hsl(var(--brand-hue), calc(var(--brand-saturation)/2), calc(var(--brand-lightness)/1.5));
  --text1-dark: hsl(var(--brand-hue) 15% 87%);
  --text2-dark: hsl(var(--brand-hue) 5% 76%);
  --surface1-dark: hsl(var(--brand-hue) 10% 10%);
  --surface2-dark: hsl(var(--brand-hue) 10% 15%);
  --surface3-dark: hsl(var(--brand-hue) 5%  20%);
  --surface4-dark: hsl(var(--brand-hue) 5% 25%);
  --surface-shadow-dark: hsl(var(--brand-hue) 50% 3%);
  --surface-shadow-strength: .8;
}
#light-mode {
  background-color:var(--surface1-light);
}

.brand-light {
  background-color: var(--brand-light);
}
.text1-light {
  color:var(--text1-light);
}
.text2-light {
  color:var(--text2-light);
}
.surface1-light {
  background-color:var(--surface1-light);
}
.surface2-light {
  background-color:var(--surface2-light);
}
.surface3-light {
  border-radius: 10px;
}
.surface4-light {
  background-color: var(--surface4-light);
}
.shadow-light {
  box-shadow: 4px 4px 10px var(--surface-shadow-light);
}

#dark-mode {
  background-color:var(--surface1-dark);
}
.brand-dark {
  background-color: var(--brand-dark);
}
.text1-dark {
    color:var(--text1-dark);
}
.text2-dark {
    color:var(--text2-dark);
}
.surface1-dark {
  background-color:var(--surface1-dark);
}
.surface2-dark {
  background-color:var(--surface2-dark);
}
.surface3-dark {
  background-color: var(--surface3-dark);
}
.surface4-dark {
  background-color: var(--surface4-dark);
}
.shadow-dark {
  box-shadow: 4px 4px 10px var(--surface-shadow-dark);
}

.link:hover {
    color: var(--text1-dark);
}
.btn-hover:hover {
    background-color: var(--brand-dark);
    color: var(--text2-dark);
}
